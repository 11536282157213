import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"]}],\"variableName\":\"ibmPlexSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/vercel/path0/src/components/layout/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/Navigation/Navigation.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/layout/Header/Header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/vercel/path0/src/components/ui/Logo/Logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/vercel/path0/src/components/ui/NavLink/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Metrika"] */ "/vercel/path0/src/scripts/YandexMetrika.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.scss");
